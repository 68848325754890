import React, { Component } from 'react';
import {
	Button,
	Box,
	InputAdornment,
	TextField,
	Link,
	Typography,
	Checkbox,
	FormControlLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Api from '../../api/Api';
import SegmentUtil from '../../util/SegmentUtil';
import { PreferencesContext } from '../../providers/PreferencesProvider';
import icons from '../../style/icons';
import {
	buttonStyles,
	passwordStyles,
	linkStyles,
	loginStyles
} from '../../style/styles';
import VerifyEmailDialog from './verify/VerifyEmailDialog';
import { colors } from '../../style/colors';

const useStyles = () => ({
	...buttonStyles,
	...passwordStyles,
	...linkStyles,
	...loginStyles
});

class Login extends Component {
	static contextType = PreferencesContext;

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			email: this.props.email,
			pass: '',
			error: null,
			loggedIn: false,
			showPass: false,
			verifyEmail: false,
			requiresMFA: false,
			userId: null,
			otp: '',
			rememberBrowser: true
		};
	}

	validateForm() {
		if (this.state.requiresMFA) {
			return this.state.otp.length === 6;
		}
		return this.state.email.length > 0 && this.state.pass.length > 0;
	}

	componentDidMount() {
		const { cookies } = this.props;
		// remove our old login cookie
		cookies.remove('jwt');
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleLogin = ev => {
		ev.preventDefault();
		ev.stopPropagation();

		const { cookies } = this.props;
		// remove our old login cookie
		cookies.remove('jwt');

		Api.login(this.state)
			.then(res => {
				if (res.data.requiresMFA) {
					this.setState({
						requiresMFA: true,
						userId: res.data.id,
						error: null
					});
				} else if (res.data.emailverified) {
					if (res.data.token) {
						const expiry = new Date();
						expiry.setDate(new Date().getDate() + 30);
						cookies.set('jwt', res.data.token, {
							domain: '.conserv.io',
							path: '/',
							secure: false,
							expires: expiry
						});
						cookies.set('cuser', {
							email: res.data.email,
							first_name: res.data.first_name,
							last_name: res.data.last_name,
							role: res.data.role,
							customerId: res.data.customerId,
							uuid: res.data.uuid
						});
						SegmentUtil.identifyAndGroup(res.data);
						this.setState({ loggedIn: true, error: false }, () => {
							this.props.onLogin();

							const urlParams = new URLSearchParams(window.location.search);
							if (urlParams.get('redirected')) {
								const path = decodeURI(urlParams.get('path'));
								const query = decodeURI(urlParams.get('query'));
								window.location.href = path + (query.startsWith('?') ? query : '?' + query);
							} else {
								window.location.href = '/';
							}
						});
					} else {
						// handle a no token case?
						this.props.onError({
							message:
								'We could not log you in, please check your email / password',
							status: error.response.status
						});
					}
					// drop the payload (object describing the user) into LocalStorage for later use with Segment
					if (res.data.payload) {
						localStorage.setItem(
							'conservuser',
							JSON.stringify(res.data.payload)
						);
					}
				} else {
					// if the users email is not verified, then we can't log them in.  Show dialog modal
					this.setState({ verifyEmail: true });
				}
			})
			.catch(error => {
				this.props.onError({
					message:
						'We could not log you in, please check your email / password',
					status: error.response.status
				});
			});
	};

	handleForgotPassword = () => {
		this.props.onForgotPassword();
	};

	handleVerifyMFA = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();

		Api.verifyMFA({
			userId: this.state.userId,
			otp: this.state.otp,
			rememberBrowser: this.state.rememberBrowser
		})
		.then(res => {
			if (res.data.token) {
				const expiry = new Date();
				expiry.setDate(new Date().getDate() + 30);
				this.props.cookies.set('jwt', res.data.token, {
					domain: '.conserv.io',
					path: '/',
					secure: false,
					expires: expiry
				});
				this.setState({ loggedIn: true, error: false }, () => {
					this.props.onLogin();
					window.location.href = '/';
				});
			}
		})
		.catch(error => {
			this.setState({ 
				error: 'Invalid verification code. Please try again.',
				otp: '' 
			});
		});
	}

	render() {
		const { classes } = this.props;
		const { requiresMFA, otp, error, rememberBrowser } = this.state;

		return (
			<form onSubmit={requiresMFA ? this.handleVerifyMFA : this.handleLogin}>
				<VerifyEmailDialog
					open={this.state.verifyEmail}
					showResend
					onClose={() => this.setState({ verifyEmail: false })}
					onError={error =>
						this.props.onError({
							message: (
								<>
									<div>
										There was a problem with your registration attempt, please
										try again. If this continues
									</div>
									<Link
										classes={{ root: classes.errorLink }}
										href='https://support.conserv.io/knowledge/kb-tickets/new'
										target='new'
									>
										contact Conserv support
									</Link>
								</>
							),
							status: error.response.status
						})
					}
					email={this.state.email}
				/>
				{!requiresMFA ? (
					<Box>
						<Box pb={1} mb={1}>
							<TextField
								label='Email'
								id='email'
								data-testid='email-textbox'
								variant='outlined'
								placeholder='Email'
								type='email'
								value={this.state.email}
								onChange={this.handleChange}
								autoComplete='email'
								InputLabelProps={{ shrink: true }}
								InputProps={{
									classes: {
										input: classes.input
									}
								}}
							/>
						</Box>
						<Box>
							<TextField
								id='pass'
								data-testid='password-textbox'
								variant='outlined'
								error={Boolean(this.state.loginError)}
								helperText={this.state.loginError}
								placeholder='Password'
								label='Password'
								type={this.state.showPass ? 'text' : 'password'}
								value={this.state.pass}
								onChange={this.handleChange}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									classes: {
										input: classes.input,
										autoComplete: 'new-password',
									},
									endAdornment: (
										<InputAdornment postiion='end'>
											<Icon
												color={`${colors.black.mediumOpacity}`}
												size={1}
												path={this.state.showPass ? icons.hide : icons.show}
												onClick={() =>
													this.setState({ showPass: !this.state.showPass })
												}
											/>
										</InputAdornment>
									)
								}}
							/>
						</Box>
						<Box mb={5} ml={2}>
							<Link
								href='#'
								classes={{ root: classes.forgotPassword }}
								onClick={this.handleForgotPassword}
							>
								Forgot password?
							</Link>
						</Box>
						<Box display='flex' justifyContent='center' mb={4}>
							<Button
								type='submit'
								color='primary'
								variant='contained'
								disabled={!this.validateForm()}
								onClick={this.handleLogin}
								classes={{ root: classes.singInButton }}
								data-cy='signIn'
								data-testid='signin-button'
							>
								Log In
							</Button>
						</Box>
					</Box>
				) : (
					<Box>
						<Typography variant="body1" paragraph>
							We've sent a verification code to your email. Please enter it below to complete your login.
						</Typography>
						<Box pb={1} mb={1}>
							<TextField
								id="otp"
								variant="outlined"
								data-testid="otp-textbox"
								placeholder="Enter 6-digit code"
								value={otp}
								onChange={(e) => this.setState({ otp: e.target.value })}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									classes: {
										input: classes.input
									},
									maxLength: 6,
									autoComplete: 'off',
									type: 'text',
									name: 'otp-input'
								}}
								autoComplete="other"
								type="text"
								inputProps={{
									autoComplete: "other",
									"data-form-type": "other",
									"data-lpignore": "true"
								}}
								error={Boolean(error)}
								helperText={error}
							/>
						</Box>
						<Box pb={2} ml={1}>
							<FormControlLabel
								control={
									<Checkbox
										checked={rememberBrowser}
										onChange={(e) => this.setState({ rememberBrowser: e.target.checked })}
										color="primary"
									/>
								}
								label="Remember this browser"
							/>
						</Box>
						<Box display="flex" justifyContent="center" mb={4}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								data-testid="verifyMFA-button"
								disabled={!this.validateForm()}
								classes={{ root: classes.singInButton }}
								data-cy="verifyMFA"
							>
								Verify
							</Button>
						</Box>
					</Box>
				)}
			</form>
		);
	}
}

export default withCookies(withStyles(useStyles, { withTheme: true })(Login));
